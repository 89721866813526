//=require ../../../../../node_modules/jquery/dist/jquery.min.js
//=require ../../../../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.min.js
//=require ../../../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min.js
//=require ../../../../../node_modules/owl.carousel2/dist/owl.carousel.min.js
//=require ../../../../../node_modules/selectric/src/jquery.selectric.js

jQuery(window).load(function () {
    'use strict';
    // Page Preloader
    jQuery('#preloader').delay(350).fadeOut(function () {
        jQuery('body').delay(350).css({ 'overflow': 'visible' });
    });
});

$('.telefone').mask('(00)00000-0000');

$('select').selectric();

$('.owl-carousel').owlCarousel({
    loop    : true,
    nav     : true,
    items   : 1,
    autoplay: true
});

$('.botaoResponsivo').on('click', function () {
    $('.menu nav').toggleClass('ativo');
    $('.linhasmr').toggleClass('ativo');
    if ($('.linhasmr').hasClass('ativo')) {
        $('.linhasmr').addClass('ativo');
    } else {
        $('.linhasmr').removeClass('ativo');
    }
    return false;
});

/****************************** FUNCAO TESTA EMAIL **********************/

function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (((email.search(exclude) != -1) || (email.search(check)) == -1) || (email.search(checkend) == -1)) {
        return false;
    } else {
        return true;
    }
}

$('#formContato').submit(function (e) {
    e.preventDefault();

    $('#btnEnviarContato').html('<i class="fa fa-spinner fa-spin"></i>Aguarde...');

    var name = $('#nome').val();
    var email = $('#email').val();
    var assunto = $('#assunto').val();
    var mensagem = $('#mensagem').val();
    var errors = 0;

    if (name == '') {
        $('#nome').addClass('errorField');
        errors++;
    } else { $('#nome').removeClass('errorField'); }
    if (email == '' || !IsEmail(email)) {
        $('#email').addClass('errorField');
        errors++;
    } else { $('#email').removeClass('errorField'); }
    if (assunto == '') {
        $('#assunto').addClass('errorField');
        errors++;
    } else { $('#assunto').removeClass('errorField'); }
    if (mensagem == '') {
        $('#mensagem').addClass('errorField');
        errors++;
    } else { $('#mensagem').removeClass('errorField'); }

    if (errors == 0) {
        $.post(url + '/email/form_contato.php', $('#formContato').serialize(), function (data) {
            if (data == '1') {
                $('.erroContato').fadeOut('slow');
                $('#formContato')[0].reset();
                $('.sucessoContato').fadeIn('slow');
                $('#btnEnviarContato').html('Enviar');
            }
        });
    } else {
        $('.erroContato').fadeIn('slow');
        $('#btnEnviarContato').html('Enviar');
    }
});

$('#formCadastro').submit(function (e) {
    e.preventDefault();

    $('#btnEnviarCadastro').html('<i class="fa fa-spinner fa-spin"></i>Aguarde...');

    var name = $('#nomeCadastro').val();
    var email = $('#emailCadastro').val();
    var telefone = $('#telefoneCadastro').val();
    var errors = 0;

    if (name == '') {
        $('#nome').addClass('errorField');
        errors++;
    } else { $('#nome').removeClass('errorField'); }
    if (email == '' || !IsEmail(email)) {
        $('#email').addClass('errorField');
        errors++;
    } else { $('#email').removeClass('errorField'); }
    if (telefone == '') {
        $('#telefoneCadastro').addClass('errorField');
        errors++;
    } else { $('#telefoneCadastro').removeClass('errorField'); }

    if (errors == 0) {
        $.post(url + '/email/form_curso.php', $('#formCadastro').serialize(), function (data) {
            if (data == '1') {
                $('.erroCadastro').fadeOut('slow');
                $('#formCadastro')[0].reset();
                $('.sucessoCadastro').fadeIn('slow');
                $('#btnEnviarCadastro').html('Enviar');
            }
        });
    } else {
        $('.erroCadastro').fadeIn('slow');
        $('#btnEnviarCadastro').html('Enviar');
    }
});